import { Box, Container, Typography } from '@mui/material';

export const AuthLayoutFooter = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ p: 2, pt: 4 }}>
        <Typography align="center" variant="body2" sx={{ opacity: 0.5 }}>
          © Copyright 2022 BRiX Financial Technologies Holding Corporation. All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default AuthLayoutFooter;
